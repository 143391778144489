import FavSelected from "assets/Star.svg";
import Fav from "assets/StarOutlined.svg";
import errorCircle from "assets/errorCircle.svg";
import imageDefault from "assets/imageDefault.png";
import marker from "assets/marker.svg";
import starWhite from "assets/starWhite.svg";

export const texts = {
  fumico: "kd meu pod",
  next: "avançar",
  back: "voltar",
  fav: "favoritos",
  recents: "recentes",
  selectAddress: "selecione o seu endereço",
  orderPrevious: "previsão de entrega",
  orderActive: "continue o seu pedido",
  outOfArea: "endereço fora da área de entrega",
  noAddressesFavorites: "você não possui endereços favoritos.",
};

export const images = {
  imageDefault,
  Fav,
  FavSelected,
  errorCircle,
  starWhite,
  marker,
};
